import { get } from 'lodash'
import AppTitle from '@theme/components/atom/AppTitle'

export default {
  props: {
    headingClass: {
      type: String,
      default() {
        return this.$themeSettings.components.InstagramWidget.headingClass
      },
    },
    headingLevel: {
      type: Number,
      default() {
        return this.$themeSettings.components.InstagramWidget.headingLevel
      },
    },
  },
  components: {
    AppTitle,
  },
  mounted() {
    this.initWitget()
  },
  computed: {
    getWidgetSrc() {
      const lightwidgetInstagramId = get(this.$contentSettings, 'lightwidgetInstagramId')
      return lightwidgetInstagramId ? 'https://cdn.lightwidget.com/widgets/' + lightwidgetInstagramId + '.html' : false
    },
    linkInstagram() {
      let url = null
      const socialNetworkLinks = get(this.$store, 'state.globals.footer.social_links')

      if (socialNetworkLinks) {
        const instagram = socialNetworkLinks.find(item => get(item, 'entity.social_type[0]') === 'instagram')
        url = get(instagram, 'entity.url') || null
      }

      return url
    },
  },
  methods: {
    initWitget() {
      let script = document.createElement('script')
      script.type = 'text/javascript'
      script.async = true
      script.defer = true
      script.src = 'https://cdn.lightwidget.com/widgets/lightwidget.js'
      let s = document.getElementsByTagName('script')[0]
      s.parentNode.insertBefore(script, s)
    },
  },
}
